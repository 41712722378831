.landing-page {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: #faf4f4;

  .logo {
    flex: 1 1 auto;
    vertical-align: middle;
    display: flex;
    width: 40%;
    justify-content: center;

    img {
      width: 90%;
      text-align: center;
      margin-left: 20px;
    }
  }

  .form {
    text-align: center;
    position: relative;
    flex: 1 1 auto;

    .error-message {
      position: absolute;
      top: -31px;
      left: 0;
      color: rgb(141, 46, 30);
    }

    input {
      width: 100%;
      height: 40px;
      margin-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      &.input-error {
        border-color: red;
      }
    }

    button {
      font-weight: bold;
      width: 100%;
      margin: 0;
      font-size: 1.5rem;
    }

    .or-bubble {
      height: 48px;
      line-height: 46px;
      font-weight: lighter;
      color: #63707f;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .button-create {
      background-color: #ffa41b;
      border-color: #ffa41b;
    }

    .button-join {
      background-color: #005082;
      border-color: #005082;
      color: #fff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

@media (min-width: 800px) {
  .landing-page.frame {
    .form {
      width: 30%;
    }
  }
}

@media (max-width: 799px) {
  .landing-page.frame {
    .logo {
      width: 60%;

      img {
        width: 100%;
      }
    }
    .form {
      width: 60%;
    }
  }
}

@media (max-width: 550px) {
  .landing-page.frame {
    .logo {
      width: 90%;
    }
    .form {
      width: 90%;
    }
  }
}
